export default function CircleRight() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17.5"
        cy="17.5"
        r="17.5"
        transform="rotate(180 17.5 17.5)"
        fill="#D6D6D6"
      />
      <path
        d="M15.0719 10.9712L15.0719 10.9713L21.1795 17.4928L15.0719 24.0272C14.8427 24.2724 14.8427 24.6681 15.0719 24.9132C15.3046 25.1623 15.6857 25.1623 15.9185 24.9132L22.4281 17.9487C22.543 17.8258 22.6 17.6718 22.6 17.5057C22.6 17.3537 22.5438 17.1864 22.4281 17.0627L15.9195 10.0992C15.6868 9.83598 15.3048 9.836 15.0719 10.0852C14.8427 10.3304 14.8427 10.726 15.0719 10.9712Z"
        fill="#494949"
        stroke="#494949"
        strokeWidth="0.2"
      />
    </svg>
  );
}
