export default function Esc() {
  return (
    <svg
      width="33"
      height="18"
      viewBox="0 0 54 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.54543C0 4.73796 1.46525 3.27271 3.27273 3.27271H47.4545C49.262 3.27271 50.7273 4.73796 50.7273 6.54543V26.1818C50.7273 27.9893 49.262 29.4545 47.4545 29.4545H3.27273C1.46525 29.4545 0 27.9893 0 26.1818V6.54543Z"
        fill="#4A4851"
      />
      <path
        d="M3.27271 3.27273C3.27271 1.46525 4.73796 0 6.54543 0H50.7273C52.5347 0 54 1.46525 54 3.27273V22.9091C54 24.7166 52.5347 26.1818 50.7273 26.1818H6.54543C4.73796 26.1818 3.27271 24.7166 3.27271 22.9091V3.27273Z"
        fill="#35343B"
      />
      <path
        d="M17.3979 8.73167H23.5376V10.224H19.0474V12.5019H23.3019V13.9942H19.0474V16.5077H23.7732V18H17.3979V8.73167Z"
        fill="white"
      />
      <path
        d="M29.9433 10.7215C29.7688 10.4684 29.5332 10.2851 29.2364 10.1717C28.9484 10.0495 28.643 9.9884 28.3201 9.9884C28.1281 9.9884 27.9404 10.0102 27.7572 10.0539C27.5826 10.0975 27.4212 10.1673 27.2728 10.2633C27.1332 10.3593 27.0197 10.4859 26.9324 10.6429C26.8452 10.7913 26.8015 10.9702 26.8015 11.1797C26.8015 11.4939 26.9106 11.7339 27.1288 11.8997C27.347 12.0655 27.6175 12.2095 27.9404 12.3317C28.2633 12.4539 28.6168 12.5717 29.0008 12.6851C29.3848 12.7986 29.7382 12.9557 30.0612 13.1564C30.3841 13.3571 30.6546 13.6233 30.8728 13.9549C31.091 14.2866 31.2001 14.7273 31.2001 15.2771C31.2001 15.7746 31.1084 16.2109 30.9252 16.5862C30.7419 16.9528 30.4932 17.2582 30.179 17.5026C29.8735 17.7469 29.5157 17.9302 29.1055 18.0524C28.6953 18.1746 28.2633 18.2357 27.8095 18.2357C27.2335 18.2357 26.6793 18.1397 26.147 17.9477C25.6146 17.7557 25.1564 17.4328 24.7724 16.9789L26.0161 15.7746C26.2168 16.08 26.4786 16.32 26.8015 16.4946C27.1332 16.6604 27.4822 16.7433 27.8488 16.7433C28.0408 16.7433 28.2328 16.7171 28.4248 16.6648C28.6168 16.6124 28.7913 16.5339 28.9484 16.4291C29.1055 16.3244 29.2321 16.1935 29.3281 16.0364C29.4241 15.8706 29.4721 15.6829 29.4721 15.4735C29.4721 15.1331 29.363 14.8713 29.1448 14.688C28.9266 14.5048 28.6561 14.352 28.3332 14.2299C28.0102 14.0989 27.6568 13.9768 27.2728 13.8633C26.8888 13.7499 26.5353 13.5971 26.2124 13.4051C25.8895 13.2044 25.619 12.9426 25.4008 12.6197C25.1826 12.288 25.0735 11.8473 25.0735 11.2975C25.0735 10.8175 25.1695 10.4029 25.3615 10.0539C25.5622 9.70476 25.8197 9.41676 26.1339 9.18985C26.4568 8.95421 26.8233 8.77967 27.2335 8.66621C27.6437 8.55276 28.0626 8.49603 28.4902 8.49603C28.979 8.49603 29.4502 8.57021 29.9041 8.71858C30.3666 8.86694 30.7812 9.11131 31.1477 9.45167L29.9433 10.7215Z"
        fill="white"
      />
      <path
        d="M39.28 10.9048C38.9397 10.5382 38.608 10.2939 38.2851 10.1717C37.9709 10.0495 37.6524 9.9884 37.3295 9.9884C36.8495 9.9884 36.4131 10.0757 36.0204 10.2502C35.6364 10.416 35.3047 10.6517 35.0255 10.9571C34.7462 11.2539 34.528 11.6029 34.3709 12.0044C34.2226 12.4059 34.1484 12.8379 34.1484 13.3004C34.1484 13.7979 34.2226 14.256 34.3709 14.6749C34.528 15.0939 34.7462 15.456 35.0255 15.7615C35.3047 16.0669 35.6364 16.3069 36.0204 16.4815C36.4131 16.656 36.8495 16.7433 37.3295 16.7433C37.7047 16.7433 38.0669 16.656 38.416 16.4815C38.7738 16.2982 39.1055 16.0102 39.4109 15.6175L40.7724 16.5862C40.3535 17.1622 39.8429 17.5811 39.2407 17.8429C38.6386 18.1048 37.9971 18.2357 37.3164 18.2357C36.6007 18.2357 35.9418 18.1222 35.3397 17.8953C34.7462 17.6597 34.2313 17.3324 33.7949 16.9135C33.3673 16.4859 33.0313 15.9753 32.7869 15.3819C32.5426 14.7884 32.4204 14.1295 32.4204 13.4051C32.4204 12.6633 32.5426 11.9913 32.7869 11.3891C33.0313 10.7782 33.3673 10.2589 33.7949 9.83131C34.2313 9.40367 34.7462 9.0764 35.3397 8.84949C35.9418 8.61385 36.6007 8.49603 37.3164 8.49603C37.9447 8.49603 38.5251 8.60949 39.0575 8.8364C39.5986 9.05458 40.1004 9.42985 40.5629 9.96221L39.28 10.9048Z"
        fill="white"
      />
    </svg>
  );
}
