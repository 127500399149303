import React from "react";
import ACPWidget, { ACPWidgetProps } from ".";

const ACPColumnWidget: React.FC<ACPWidgetProps> = ({ widget }) => {
  return (
    <>
      {/* <div className={`grid gap-4 w-full grid-cols-${widget.config.columns}`}> */}
      <div className="w-100 center mv4">
        {widget.children.map((childWidget, widgetIn) => {
          return (
            <div key={childWidget?.id ?? widgetIn}>
              {childWidget && <ACPWidget widget={childWidget} />}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ACPColumnWidget;
