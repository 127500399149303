import React from "react";
import ACPWidget, { ACPWidgetProps } from ".";

const ACPRowWidget: React.FC<ACPWidgetProps> = ({ widget }) => {
  return (
    <div className="w-100 center mv4">
      <div className="cf">
        {widget.children.map((childWidget, widgetIn) => {
          return (
            <div
              className={`fl acp-${widget.config.columns}-col ${
                widgetIn !== 0 ? "pa2" : "pr2 pt2 pb2"
              }`}
              key={childWidget?.id ?? widgetIn}
            >
              {childWidget && <ACPWidget widget={childWidget} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ACPRowWidget;
