import React from "react";
import { ProjectItemEndpointType } from "../../types";
import JsonField from "../JsonDisplayField";

interface EndpointPageContentProps {
  item: ProjectItemEndpointType;
  darkMode: boolean;
}

export default function EndpointPageContent({
  item,
}: EndpointPageContentProps) {
  return (
    <div className="endpointPageContentComponent">
      {item.request && (
        <JsonField title={"Example Request"} value={item.request} />
      )}

      {item.response && (
        <JsonField title={"Example Response"} value={item.response} />
      )}
      <div className="right"></div>
    </div>
  );
}
