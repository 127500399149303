export default function Open() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 1.125C8.69352 1.125 1.125 8.69353 1.125 18C1.125 27.3065 8.69352 34.875 18 34.875C27.3065 34.875 34.875 27.3065 34.875 18C34.875 8.69353 27.3065 1.125 18 1.125ZM18 3.375C26.0905 3.375 32.625 9.90952 32.625 18C32.625 26.0905 26.0905 32.6239 18 32.6239C9.90951 32.6239 3.3761 26.0905 3.3761 18C3.3761 9.90952 9.90951 3.375 18 3.375ZM17.9835 10.1107C17.6859 10.1151 17.4022 10.2372 17.1944 10.4503C16.9867 10.6634 16.8718 10.9502 16.875 11.2478V16.8739H11.2489C10.9504 16.8739 10.6641 16.9925 10.453 17.2036C10.2419 17.4146 10.1234 17.7009 10.1234 17.9995C10.1234 18.298 10.2419 18.5843 10.453 18.7953C10.6641 19.0064 10.9504 19.125 11.2489 19.125H16.875V24.7511C16.875 24.8989 16.9041 25.0453 16.9607 25.1818C17.0172 25.3184 17.1001 25.4425 17.2047 25.547C17.3092 25.6515 17.4333 25.7344 17.5698 25.791C17.7064 25.8475 17.8527 25.8766 18.0005 25.8766C18.1484 25.8766 18.2947 25.8475 18.4313 25.791C18.5678 25.7344 18.6919 25.6515 18.7964 25.547C18.901 25.4425 18.9839 25.3184 19.0404 25.1818C19.097 25.0453 19.1261 24.8989 19.1261 24.7511V19.125H24.7522C25.0507 19.125 25.337 19.0064 25.5481 18.7953C25.7592 18.5843 25.8777 18.298 25.8777 17.9995C25.8777 17.7009 25.7592 17.4146 25.5481 17.2036C25.337 16.9925 25.0507 16.8739 24.7522 16.8739H19.1261V11.2478C19.1277 11.0976 19.0992 10.9485 19.0423 10.8095C18.9854 10.6704 18.9012 10.5442 18.7947 10.4382C18.6882 10.3322 18.5616 10.2486 18.4222 10.1924C18.2829 10.1361 18.1337 10.1084 17.9835 10.1107V10.1107Z"
        fill="currentColor"
      />
    </svg>
  );
}
