import CopyToClipboard from "./CopyToClipboard";
import { AiOutlineCopy } from "react-icons/ai";
import { ProjectItemEndpointType } from "../types";

const colorMappings = {
  GET: {
    bg: "#609C3B",
    color: "white",
  },
  POST: {
    bg: "#CCA414",
    color: "white",
  },
  PUT: {
    bg: "#3C54AB",
    color: "white",
  },
  PATCH: {
    bg: "#80519C",
    color: "white",
  },
  DELETE: {
    bg: "#B03131",
    color: "white",
  },
  COPY: {
    bg: "#80519C",
    color: "white",
  },
  HEAD: {
    bg: "#80519C",
    color: "white",
  },
  OPTIONS: {
    bg: "grey",
    color: "white",
  },
  LINK: {
    bg: "#80519C",
    color: "white",
  },
  UNLINK: {
    bg: "#80519C",
    color: "white",
  },
  PURGE: {
    bg: "#80519C",
    color: "white",
  },
  LOCK: {
    bg: "#80519C",
    color: "white",
  },
  UNLOCK: {
    bg: "#80519C",
    color: "white",
  },
  PROPFIND: {
    bg: "#80519C",
    color: "white",
  },
  VIEW: {
    bg: "#80519C",
    color: "white",
  },
};

interface EndpointDisplayComponentProps {
  item: ProjectItemEndpointType;
}
const EndpointDisplayComponent: React.FC<EndpointDisplayComponentProps> = ({
  item,
}) => {
  return (
    <div className="endpointEndpoint">
      <div className="detis">
        <span
          className="method"
          style={{
            backgroundColor: colorMappings[item.method].bg,
            color: colorMappings[item.method].color,
          }}
        >
          {item.method}
        </span>
        <span className="endpoint">{item.endpoint}</span>
      </div>
      <CopyToClipboard value={item.endpoint}>
        <AiOutlineCopy className="copy" />
      </CopyToClipboard>
    </div>
  );
};

export default EndpointDisplayComponent;
