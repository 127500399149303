import axios from "axios";

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL;

axios.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

axios.interceptors.request.use(
  (config) => {
    try {
      if (window.localStorage.getItem("application_id")) {
        config.headers["DC-App-Id"] = `${window.localStorage.getItem(
          "application_id"
        )}`;
      }

      if (window.localStorage.getItem("version_code")) {
        config.headers["DC-Version-Code"] =
          window.localStorage.getItem("version_code");
      }

      if (window.localStorage.getItem("token")) {
        config.headers["DC-Auth"] = window.localStorage.getItem("token");
      }
    } catch (error) {}

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => {
    return { data: response.data, headers: response.headers };
  },
  (err) => {
    if (err.response.status === 401) {
      window.localStorage.clear();
      // @ts-ignore
      window.location = "/";
      return;
    }

    if (err.response.status === 503) {
      // @ts-ignore
      window.location = "/system-maintenance";
      return;
    }

    return Promise.reject(err);
  }
);

export default axios;
