import Head from "next/head";
import DocsSidebar from "../../../components/SideBar";
import ReactTooltip from "react-tooltip";
import useToast from "../../../utils/withToast";
import PoweredBy from "../../../components/PoweredBy";
import { useEffect, useState } from "react";
import ParameterViewModal from "../../../components/Modals/ParameterViewModal";
import Modal from "../../../components/Modal";
import OutputPageSection from "../../../components/OutputPageSection";
import axios from "../../../plugins/axios";
import withAuth from "../../../utils/withAuth";
import { ProjectType } from "../../../types";
import { isGroup } from "../../../utils/contentTypeCheck";

interface HomePageProps {
  project: ProjectType;
}

const PageHome = ({ project }: HomePageProps) => {
  const toast = useToast();

  const [activeRef, setActiveRef] = useState("INTRO-PAGE");
  const [darkMode, setDarkMode] = useState(true);

  const [showParameter, setShowParameter] = useState(null);
  const [showParameters, setShowParameters] = useState([]);
  const [currentProject, setCurrentProject] = useState(project);

  useEffect(() => {
    setCurrentProject(project);
  }, [project]);

  useEffect(() => {
    if (
      window.localStorage.getItem("darkMode") &&
      window.localStorage.getItem("darkMode") === "false"
    ) {
      setDarkMode(false);
    }

    window.onresize = function () {
      location.reload();
    };

    var interval = setInterval(async () => {
      if (!document.hidden) {
        await axios.post("docs/ping");
      }
    }, 30000);
  }, []);

  const toggleDarkMode = () => {
    window.localStorage.setItem("darkMode", String(!darkMode));
    setDarkMode(!darkMode);

    const message = !darkMode
      ? "Switched to dark mode!"
      : "Switched out of dark mode!";

    toast(message, "info");
  };

  const setActiveParams = (parameters, index) => {
    setShowParameters(parameters);
    setShowParameter(index);
  };

  return (
    <div
      className="container "
      data-theme={darkMode ? "dark" : "light"}
      key={currentProject.id}
    >
      <Head>
        <title>
          {currentProject.project_name}
          {currentProject.versions.length > 1
            ? `(${currentProject.name})`
            : ""}{" "}
          | Docbloc
        </title>
        <link rel="icon" href={project.icon} />
      </Head>
      <div className="main hide-scrollbar">
        <DocsSidebar
          project={currentProject}
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
          activeRef={activeRef}
          setActiveRef={setActiveRef}
        />
        <div className="MainContent hide-scrollbar">
          {currentProject.items.map((tm, index) => (
            <div key={index} className="section">
              <OutputPageSection
                item={tm}
                key={index}
                darkMode={darkMode}
                showParams={setActiveParams}
              />
              {isGroup(tm) &&
                tm.items.map((child, cind) => (
                  <OutputPageSection
                    item={child}
                    key={cind}
                    darkMode={darkMode}
                    showParams={setActiveParams}
                  />
                ))}
            </div>
          ))}

          <Modal
            shown={showParameter != null}
            dismissible={true}
            onClose={() => setActiveParams([], null)}
          >
            <ParameterViewModal
              onClose={() => setActiveParams([], null)}
              params={showParameters}
              current={showParameter}
            />
          </Modal>
        </div>
      </div>
      <ReactTooltip place="right" type="dark" effect="solid" />
      <PoweredBy darkMode={darkMode} />
    </div>
  );
};

export async function getStaticPaths() {
  let projects = [];
  try {
    const { data } = await axios.get("docs/ssr/projects");
    projects = data;
  } catch (error) {
    console.error(error);
  }

  const paths = projects.reduce((current, project) => {
    const domains = project.versions.map((version) => ({
      params: {
        site: project.domain,
        versionCode:
          version.state === "CURRENT" ? null : [version.code.toString()],
      },
    }));
    const custom_urls = project.custom_url
      ? project.versions.map((version) => ({
          params: {
            site: project.custom_url,
            versionCode:
              version.state === "CURRENT" ? null : [version.code.toString()],
          },
        }))
      : [];
    return [...current, ...domains, ...custom_urls];
  }, []);

  console.log(paths);

  return {
    paths: paths,
    fallback: "blocking",
  };
}

export async function getStaticProps({ params: { site, versionCode } }) {
  try {
    const version = versionCode ? versionCode[0] : undefined;
    const { data } = await axios.get(`docs/get/${site}`, {
      params:
        version !== undefined
          ? {
              version,
            }
          : {},
    });

    return {
      props: {
        project: data,
      },
      revalidate: 30,
    };
  } catch (error) {
    console.log([
      error.response.status,
      error.response.data,
      site,
      versionCode,
    ]);
    return {
      props: {
        project: null,
      },
      notFound: true,
      revalidate: 30,
    };
  }
}

export default withAuth(PageHome);
