import React from "react";
import { BiLink } from "react-icons/bi";
import CopyToClipboard from "./CopyToClipboard";
import { useRouter } from "next/router";
import NoSSR from "./NoSSR";

interface SectionHeaderProps {
  title: string;
  idValue?: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, idValue }) => {
  return (
    <h1 className="relative copylinktosection">
      {idValue && (
        <NoSSR>
          <CopyAble idValue={idValue} />
        </NoSSR>
      )}
      {title}
    </h1>
  );
};

const CopyAble = ({ idValue }) => {
  return (
    <div className="copyable">
      <CopyToClipboard
        value={`${window.location.origin}${window.location.pathname}#${idValue}`}
      >
        <BiLink className="copy" />
      </CopyToClipboard>
    </div>
  );
};
export default SectionHeader;
