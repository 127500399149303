import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { ProjectItemTypeChoice } from "../../types";
import { isGroup } from "../../utils/contentTypeCheck";

interface SingleMenuItemProps {
  item: ProjectItemTypeChoice;
  index: string | number;
  isChildren?: boolean;
  expanded?: boolean;
  activeRef: string;
  setActiveRef: (v: string | number) => void;
}

const SingleMenuItem = ({
  item,
  index,
  isChildren = false,
  expanded = false,
  activeRef,
  setActiveRef,
}: SingleMenuItemProps) => {
  const [expanded2, setExpanded2] = useState(false);

  if (isGroup(item)) {
    return (
      <>
        <div
          className={`item item-head ${item.items && "with-children"}`}
          key={0}
        >
          <div className="w-100 flex justify-between">
            <a
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById(item.unique_id)
                  .scrollIntoView({ behavior: "smooth" });

                setActiveRef(index);
              }}
              href={`#${item.unique_id}`}
            >
              {item.name}
            </a>
            {expanded || expanded2 ? (
              <IoIosArrowUp
                onClick={() => setExpanded2(!expanded2)}
                size={24}
              />
            ) : (
              <IoIosArrowDown
                onClick={() => setExpanded2(!expanded2)}
                size={24}
              />
            )}
          </div>
        </div>
        <div style={{ display: expanded || expanded2 ? "block" : "none" }}>
          {item.items.map((item1, index1) => (
            <SingleMenuItem
              item={item1}
              key={index1}
              index={index}
              isChildren={true}
              activeRef={activeRef}
              setActiveRef={setActiveRef}
            />
          ))}
        </div>
      </>
    );
  } else {
    return (
      <div>
        <a
          className={`item item-head ${
            activeRef === item.unique_id && "active"
          } ${isChildren && "is-child"}`}
          href={`#${item.unique_id}`}
          key={0}
          onClick={(e) => {
            e.preventDefault();
            setActiveRef(item.unique_id);
            document
              .getElementById(item.unique_id)
              .scrollIntoView({ behavior: "smooth" });
          }}
        >
          {item.name}
        </a>
      </div>
    );
  }
};

export default SingleMenuItem;
