import React from "react";
import { ACPWidgetProps } from ".";

const ACPWysiwgWidget: React.FC<ACPWidgetProps> = ({ widget }) => {
  return (
    <div
      className="mv4"
      dangerouslySetInnerHTML={{
        __html: widget.content,
      }}
    ></div>
  );
};

export default ACPWysiwgWidget;
