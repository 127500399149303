import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

interface SelectFieldProps {
  options: { val: string | number; key: string }[];
  value: string | number | null;
  onChange: (val: string | number) => void;
  placeholder?: string;
  position?: string;
}
const SelectField: React.FC<SelectFieldProps> = ({
  options = [],
  value = null,
  onChange = (val: string | number) => {},
  placeholder = "Choose one",
  position = "bottom-left",
}) => {
  const [displayValue, setDisplayValue] = useState(placeholder);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (value) {
      const found = options.find((i) => i.val === value);
      setDisplayValue(found.key);
    }
  }, [options, value]);

  return (
    <div className={`SelectField ${position}`}>
      <div
        className={`active ${open ? "shown" : ""}`}
        onClick={() => setOpen(!open)}
      >
        {displayValue}{" "}
        {!open ? <IoIosArrowUp size={20} /> : <IoIosArrowDown size={20} />}
      </div>

      <div className={`selector ${open ? "shown" : ""}`}>
        <div className="header">
          <span>Select a document version</span>
          <div onClick={() => setOpen(false)}>
            <AiOutlineCloseCircle size={18} />
          </div>
        </div>
        {options.map((option) => (
          <div
            key={option.val}
            className={`option ${value === option.val ? "current" : ""}`}
            onClick={() => {
              onChange(option.val);
              setOpen(false);
            }}
          >
            {option.key}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectField;
