export default function Close() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7506 5.56768C23.1699 -1.013 12.4664 -1.01299 5.88571 5.56768C-0.69498 12.1484 -0.694969 22.8519 5.88571 29.4325C12.4664 36.0132 23.1699 36.0132 29.7506 29.4325C36.3312 22.8519 36.3312 12.1483 29.7506 5.56768ZM28.1596 7.15867C33.8804 12.8795 33.8804 22.1207 28.1596 27.8415C22.4387 33.5624 13.1983 33.5616 7.47747 27.8408C1.75663 22.1199 1.75663 12.8803 7.47747 7.15944C13.1983 1.43861 22.4387 1.43783 28.1596 7.15867ZM23.385 11.9099C23.1715 11.7025 22.8845 11.5883 22.5869 11.592C22.2893 11.5958 22.0053 11.7174 21.7972 11.9301L17.8189 15.9083L13.8407 11.9301C13.6296 11.719 13.3433 11.6004 13.0448 11.6004C12.7463 11.6004 12.46 11.719 12.2489 11.9301C12.0378 12.1412 11.9192 12.4275 11.9192 12.726C11.9192 13.0245 12.0378 13.3108 12.2489 13.5218L16.2271 17.5001L12.2489 21.4784C12.1444 21.5829 12.0615 21.7069 12.0049 21.8435C11.9483 21.9801 11.9192 22.1264 11.9192 22.2742C11.9192 22.422 11.9483 22.5684 12.0049 22.705C12.0615 22.8415 12.1444 22.9656 12.2489 23.0701C12.3534 23.1746 12.4775 23.2575 12.614 23.3141C12.7506 23.3707 12.897 23.3998 13.0448 23.3998C13.1926 23.3998 13.3389 23.3707 13.4755 23.3141C13.6121 23.2575 13.7361 23.1746 13.8407 23.0701L17.8189 19.0919L21.7972 23.0701C22.0082 23.2812 22.2945 23.3998 22.593 23.3998C22.8916 23.3998 23.1778 23.2812 23.3889 23.0701C23.6 22.859 23.7186 22.5728 23.7186 22.2742C23.7186 21.9757 23.6 21.6894 23.3889 21.4784L19.4107 17.5001L23.3889 13.5218C23.4963 13.4168 23.5815 13.2912 23.6396 13.1527C23.6977 13.0141 23.7274 12.8653 23.7271 12.715C23.7267 12.5648 23.6963 12.4162 23.6375 12.2779C23.5788 12.1396 23.4929 12.0145 23.385 11.9099Z"
        fill="currentColor"
      />
    </svg>
  );
}
