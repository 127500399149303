export default function Tab() {
  return (
    <svg
      width="33"
      height="18"
      viewBox="0 0 57 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.8889C0 4.98659 1.54213 3.44446 3.44444 3.44446H49.9444C51.8468 3.44446 53.3889 4.98659 53.3889 6.8889V27.5556C53.3889 29.4579 51.8468 31 49.9444 31H3.44444C1.54213 31 0 29.4579 0 27.5556V6.8889Z"
        fill="#4A4851"
      />
      <path
        d="M3.44434 3.44444C3.44434 1.54213 4.98647 0 6.88878 0H53.3888C55.2911 0 56.8332 1.54213 56.8332 3.44444V24.1111C56.8332 26.0134 55.2911 27.5556 53.3888 27.5556H6.88878C4.98647 27.5556 3.44434 26.0134 3.44434 24.1111V3.44444Z"
        fill="#35343B"
      />
      <path
        d="M20.3084 10.7605H17.3186V9.18979H25.0342V10.7605H22.0444V18.9445H20.3084V10.7605Z"
        fill="white"
      />
      <path
        d="M28.1124 9.18979H29.6142L33.8164 18.9445H31.8324L30.9231 16.7125H26.6933L25.8115 18.9445H23.8689L28.1124 9.18979ZM30.3169 15.2245L28.8151 11.2565L27.2857 15.2245H30.3169Z"
        fill="white"
      />
      <path
        d="M34.9004 9.18979H38.7031C39.0705 9.18979 39.4333 9.23571 39.7916 9.32757C40.1498 9.41023 40.4667 9.5526 40.7422 9.75468C41.027 9.94757 41.2566 10.2002 41.4311 10.5125C41.6056 10.8248 41.6929 11.2013 41.6929 11.6422C41.6929 12.1933 41.5367 12.648 41.2244 13.0062C40.9122 13.3645 40.5034 13.6216 39.9982 13.7778V13.8053C40.3013 13.8421 40.5815 13.9293 40.8387 14.0671C41.105 14.1957 41.3301 14.3656 41.5138 14.5769C41.6975 14.779 41.8399 15.0178 41.9409 15.2933C42.0511 15.5597 42.1062 15.849 42.1062 16.1613C42.1062 16.6941 42.0006 17.1396 41.7893 17.4978C41.5781 17.8468 41.2979 18.1316 40.9489 18.352C40.609 18.5633 40.2141 18.7148 39.764 18.8067C39.3231 18.8985 38.873 18.9445 38.4138 18.9445H34.9004V9.18979ZM36.6364 13.1578H38.2209C38.7996 13.1578 39.2313 13.0476 39.516 12.8271C39.8099 12.5975 39.9569 12.299 39.9569 11.9316C39.9569 11.509 39.8053 11.1968 39.5022 10.9947C39.2083 10.7834 38.7307 10.6778 38.0693 10.6778H36.6364V13.1578ZM36.6364 17.4565H38.2347C38.4551 17.4565 38.6893 17.4427 38.9373 17.4151C39.1945 17.3876 39.4287 17.3233 39.64 17.2222C39.8513 17.1212 40.0258 16.9788 40.1636 16.7951C40.3013 16.6022 40.3702 16.345 40.3702 16.0236C40.3702 15.5092 40.2003 15.151 39.8604 14.9489C39.5298 14.7468 39.0108 14.6458 38.3036 14.6458H36.6364V17.4565Z"
        fill="white"
      />
    </svg>
  );
}
