export default function CircleLeft() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#D6D6D6" />
      <path
        d="M19.9281 24.0288L19.9281 24.0287L13.8205 17.5072L19.9281 10.9728C20.1573 10.7276 20.1573 10.3319 19.9281 10.0868C19.6954 9.83774 19.3143 9.83774 19.0815 10.0868L12.5719 17.0513C12.457 17.1742 12.4 17.3282 12.4 17.4943C12.4 17.6463 12.4562 17.8136 12.5719 17.9373L19.0805 24.9008C19.3132 25.164 19.6952 25.164 19.9281 24.9148C20.1573 24.6696 20.1573 24.274 19.9281 24.0288Z"
        fill="#494949"
        stroke="#494949"
        strokeWidth="0.2"
      />
    </svg>
  );
}
