import { Circles } from "react-loader-spinner";

const AppLoading = () => {
  return (
    <div className="LoadingPage">
      <div className="inner">
        <Circles color="#12A667" height={55} width={55} />
      </div>
    </div>
  );
};

export default AppLoading;
