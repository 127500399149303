import Image from "next/image";
import React from "react";
import { ACPWidgetProps } from ".";

const ACPImageWidget: React.FC<ACPWidgetProps> = ({ widget }) => {
  return (
    <div className="mv2 relative w-100 ">
      <img src={widget.imageUrl} alt="Inner content Image" className="center db" />
    </div>
  );
};

export default ACPImageWidget;
