import EndpointPageContent from "../PageTypes/Endpoint";
import EndpointDisplayComponent from "../EndpontDisplayComponent";
import IntroductionPage from "../IntroductionPage";
import ParameterButton from "../Buttons/ParameterButton";
import {
  ParameterType,
  ProjectItemEndpointType,
  ProjectItemIntroType,
  ProjectItemTypeChoice,
} from "../../types";
import SectionHeader from "../SectionHeader";
import AdvancedContentPage from "../PageTypes/AdvancedContentPage";

export interface OutputPageSectionProps {
  item: ProjectItemTypeChoice;
  darkMode: boolean;
  showParams: (parameters: ParameterType[], index: number) => void;
}
const OutputPageSection: React.FC<OutputPageSectionProps> = ({
  item,
  darkMode,
  showParams,
}) => {
  return (
    <section id={item.unique_id} className="pageSection">
      <div
        className={`pageSectionContent ${
          item.content_model === "ProjectContentPage" && item.acp_content
            ? "full"
            : ""
        }`}
      >
        <SectionHeader title={item.name} idValue={item.unique_id} />

        {item.content_model === "ProjectEndpoint" && (
          <EndpointDisplayComponent item={item as ProjectItemEndpointType} />
        )}

        {item.content_model === "ProjectContentPage" && item.acp_content ? (
          <>
            <AdvancedContentPage item={item} />
          </>
        ) : (
          <>
            <div
              className="pageSectionContentInner"
              dangerouslySetInnerHTML={{
                __html: item.content,
              }}
            ></div>
          </>
        )}

        {item.content_model === "ProjectEndpoint" && (
          <div className="parameters-holder">
            <br />
            {
              <div className="mw6">
                {item.method == "GET" && item.parameters.length > 0 && (
                  <h3>URL Parameters</h3>
                )}
                {item.method != "GET" && item.parameters.length > 0 && (
                  <h3>Body Parameters</h3>
                )}
                {item.parameters.map((param, index) => (
                  <ParameterButton
                    title={param.name}
                    onClick={() => showParams(item.parameters, index)}
                    color="alt-dark-bg"
                    key={index}
                  />
                ))}
              </div>
            }

            <br />

            <br />
          </div>
        )}
      </div>

      {item.content_model === "ProjectEndpoint" && (
        <EndpointPageContent
          item={item as ProjectItemEndpointType}
          darkMode={darkMode}
        />
      )}
      {item.content_model === "IntroPage" && (
        <IntroductionPage item={item as ProjectItemIntroType} />
      )}
    </section>
  );
};

export default OutputPageSection;
