import { AiOutlineCopy } from "react-icons/ai";
import { ProjectItemIntroType } from "../types";
import CopyToClipboard from "./CopyToClipboard";

interface IntroductionPageProps {
  item: ProjectItemIntroType;
}
const IntroductionPage: React.FC<IntroductionPageProps> = ({ item }) => {
  return (
    <div className="endpointPageContentComponent">
      <div className="right">
        <div className="right-item">
          <div className="seperator bg-db-gray">
            <h3>Base URL</h3>

            <div className="go-right">
              <CopyToClipboard value={item.endpoint}>
                <AiOutlineCopy />
              </CopyToClipboard>
            </div>
          </div>
          <div className="endpoint-request" style={{ color: "white" }}>
            {item.endpoint}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroductionPage;
