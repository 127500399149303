import React, { useState } from "react";
import { ParameterType } from "../../types";
import ParameterButton from "../Buttons/ParameterButton";
import CircleLeft from "../Icon/icons/CircleLeft";
import CircleRight from "../Icon/icons/CircleRight";
import Close from "../Icon/icons/Close";

interface ParameterViewModalProps {
  onClose: () => void;
  params: ParameterType[];
  current: number;
}
export default function ParameterViewModal({
  onClose,
  params,
  current,
}: ParameterViewModalProps) {
  const [active, setActive] = useState(current);

  return (
    <div className="parameter-view-modal bg-db-3rd br3 overflow-scroll hide-scrollbar">
      <div className="pa2 pl3 pr3 flex justify-between items-center pointer header mb3">
        <span>{params[active].name}</span>
        <div className="flex items-center">
          {active != 0 && (
            <a
              className="br-pill bg-gray mr2 bg-db-gray3 h2 flex items-center pr2"
              onClick={() => setActive(active - 1)}
            >
              <div className="h2 w2 pa1">
                <CircleLeft />
              </div>
              {params[active - 1].name}
            </a>
          )}
          {active != params.length - 1 && (
            <a
              className="br-pill bg-gray mr2 bg-db-gray3 h2 flex items-center pl2"
              onClick={() => setActive(active + 1)}
            >
              {params[active + 1].name}

              <div className="h2 w2 pa1">
                <CircleRight />
              </div>
            </a>
          )}

          <a
            onClick={onClose}
            className="flex items-center justify-center"
            style={{ width: "18px", height: "18px" }}
          >
            <Close />
          </a>
        </div>
      </div>
      <div className="pa3 pt1 pb4 mh3 mx-h-500">
        <p className="f6">{params[active].description}</p>
        <p className="f6">
          <strong className="f6">Required:</strong>{" "}
          {params[active].required ? "Yes" : "No"}
        </p>
        <p className="f6">
          <strong className="f6">Type:</strong> {params[active].type}
        </p>
        {params[active].validation && (
          <p className="f6">
            <strong className="f6">Field rules:</strong>{" "}
            {params[active].validation}
          </p>
        )}
        <ParameterChildView params={params[active].children} />
      </div>
    </div>
  );
}

const ParameterChildView = ({
  params,
}: {
  params?: ParameterType[] | null;
}) => {
  if (!params || !params.length) return null;
  return (
    <div className="mt3">
      {params.map((param, index) => (
        <ParameterButton title={param.name} key={index} onClick={() => {}}>
          <div className="bt pa3 b--db-gray">
            <p className="f6">{param.description}</p>
            <p className="f6">
              <strong className="f6">Required:</strong>{" "}
              {param.required ? "Yes" : "No"}
            </p>
            <p className="f6">
              <strong className="f6">Type:</strong> {param.type}
            </p>
            {param.validation && (
              <p className="f6">
                <strong className="f6">Field rules:</strong> {param.validation}
              </p>
            )}
            <ParameterChildView params={param.children} />
          </div>
        </ParameterButton>
      ))}
    </div>
  );
};
