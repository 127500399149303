import { useState } from "react";
import Close from "../Icon/icons/Close";
import Open from "../Icon/icons/Open";

interface ParameterButtonProps {
  title: string;
  onClick: () => void;
  color?: string;
}

const ParameterButton: React.FC<ParameterButtonProps> = ({
  title,
  onClick,
  children = null,
  color = "bg-par-child",
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="mb2">
      <button
        onClick={children ? () => setOpen(!open) : onClick}
        className={`param-btn ba-n ${color} ${
          children && open ? "br3 br--top" : "br3"
        }  pl3 pr3 db-main h2-5 flex items-center justify-between w-100`}
      >
        {title}
        <div style={{ width: "18px", height: "18px" }}>
          {open ? <Close /> : <Open />}
        </div>
      </button>
      {children && open && (
        <div className="bg-par-child br3 br--bottom">{children}</div>
      )}
    </div>
  );
};

export default ParameterButton;
