export const AppLogo = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          id="toColor"
          d="M31.2844 0C33.0795 0 34.5347 1.45994 34.5347 3.26086V31.3858C34.5347 33.1867 33.0795 34.6466 31.2844 34.6466H3.25032C1.45522 34.6466 0 33.1867 0 31.3858V3.26086C0 1.45994 1.45522 0 3.25032 0H31.2844ZM15.0479 7.74454H11.3761L11.3155 7.74678C10.9161 7.77634 10.5969 8.09559 10.566 8.49591L10.5636 8.55976V25.6793L10.5658 25.7401C10.5952 26.1408 10.9135 26.461 11.3125 26.492L11.3761 26.4945L17.1497 26.4923C17.5512 26.4919 17.8765 26.1654 17.8768 25.7626V16.3043C17.8768 16.0953 18.0336 15.923 18.2357 15.8994L18.2831 15.8967H19.7598C19.9682 15.8967 20.1399 16.054 20.1634 16.2568L20.1661 16.3043V20.2929C20.1661 20.3007 20.1724 20.3069 20.1801 20.3069H20.194C20.5073 20.0044 20.7819 19.787 21.0176 19.6546C21.3713 19.4561 21.804 19.3568 22.3159 19.3568C22.8184 19.3568 23.2721 19.4561 23.6769 19.6546C24.0817 19.8532 24.426 20.1226 24.7099 20.4629C24.8828 20.6703 25.013 20.9161 25.1557 21.1463C25.7203 19.9907 26.0026 18.6308 26.0026 17.0666C26.0026 15.301 25.687 13.818 25.0559 12.6174C24.4248 11.4169 23.5953 10.459 22.5674 9.74401C21.5396 9.02897 20.3675 8.51696 19.0511 8.208C17.836 7.9228 16.6055 7.76922 15.3597 7.74729L15.0479 7.74454Z"
        />
      </g>
    </svg>
  );
};

export default function PoweredBy({ darkMode }: { darkMode: boolean }) {
  return (
    <a
      id="PoweredByDocbloc"
      href="https://docbloc.co"
      title="REST API documentation management tool."
      target="_blank"
      rel="noreferrer noopener"
      className={`${darkMode ? "dark" : "light"}`}
    >
      <div className="message">Powered by Docbloc</div>
      <AppLogo />
    </a>
  );
}
