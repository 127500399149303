import React from "react";

const WarningIcon: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3953 0C12.6726 0 12.0147 0.377522 11.6479 0.992344L0.279111 20.1381C-0.0876244 20.7637 -0.0984108 21.5296 0.279111 22.1444C0.645847 22.77 1.3146 23.1367 2.03729 23.1367H24.7533C25.476 23.1367 26.1448 22.77 26.5115 22.1444C26.8783 21.5296 26.8783 20.7637 26.5115 20.1381L15.1427 0.992344C14.776 0.377522 14.118 0 13.3953 0ZM13.3953 1.21886C13.665 1.21886 13.967 1.39144 14.0964 1.60717L25.4652 20.7637C25.5947 20.9795 25.5947 21.303 25.4652 21.5188C25.3358 21.7345 25.023 21.9179 24.7533 21.9179H2.03729C1.75684 21.9179 1.45482 21.7345 1.32539 21.5188C1.19595 21.303 1.19595 20.9795 1.32539 20.7637L12.6942 1.60717C12.8236 1.39144 13.1257 1.21886 13.3953 1.21886ZM13.3953 6.39631C13.0609 6.39631 12.7805 6.66596 12.7805 7.00034V15.5216C12.7805 15.8667 13.0609 16.1364 13.3953 16.1364C13.7297 16.1364 13.9993 15.8667 13.9993 15.5216V7.00034C13.9993 6.66596 13.7297 6.39631 13.3953 6.39631ZM13.3953 17.3552C12.8884 17.3552 12.4785 17.7651 12.4785 18.2613C12.4785 18.7682 12.8884 19.1781 13.3953 19.1781C13.9023 19.1781 14.3014 18.7682 14.3014 18.2613C14.3014 17.7651 13.9023 17.3552 13.3953 17.3552Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default WarningIcon;
