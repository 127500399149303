export default function UpDown() {
  return (
    <svg
      width="46"
      height="18"
      viewBox="0 0 62 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666748 5.33332C0.666748 3.86056 1.35998 2.66666 2.21514 2.66666H23.1184C23.9735 2.66666 24.6667 3.86056 24.6667 5.33332V21.3333C24.6667 22.8061 23.9735 24 23.1184 24H2.21514C1.35998 24 0.666748 22.8061 0.666748 21.3333V5.33332Z"
        fill="#4A4851"
      />
      <path
        d="M3.33325 2.66667C3.33325 1.19391 4.02649 0 4.88164 0H25.7849C26.64 0 27.3333 1.19391 27.3333 2.66667V18.6667C27.3333 20.1394 26.64 21.3333 25.7849 21.3333H4.88164C4.02649 21.3333 3.33325 20.1394 3.33325 18.6667V2.66667Z"
        fill="#35343B"
      />
      <path
        d="M14.8 5.84225C14.8 5.70841 14.8503 5.59434 14.9297 5.50832L14.9562 5.48312C14.9765 5.4638 14.9976 5.44648 15.0195 5.43108C15.065 5.40004 15.1153 5.3758 15.1683 5.35923C15.225 5.34044 15.2794 5.33298 15.3333 5.33336C15.3872 5.33298 15.4416 5.34044 15.4946 5.35647C15.5514 5.3758 15.6017 5.40004 15.6472 5.43108C15.669 5.44648 15.6902 5.4638 15.7105 5.48312L15.7369 5.50832C15.8164 5.59434 15.8667 5.70841 15.8667 5.84225V15.4921C15.8667 16.1693 14.8 16.1693 14.8 15.4921V5.84225L15.7105 6.20138L14.8 7.0684V5.84225ZM15.7369 5.50832C15.8164 5.59434 15.8667 5.70841 15.8667 5.84225V15.4921C15.8667 16.1693 14.8 16.1693 14.8 15.4921V5.84225L15.7105 6.20138L10.9105 10.7724C10.7022 10.9707 10.3645 10.9707 10.1562 10.7724C9.94793 10.574 9.94793 10.2525 10.1562 10.0541L14.9297 5.50832C14.9566 5.47927 14.9868 5.45341 15.0195 5.43108C15.0676 5.39712 15.1191 5.37251 15.1721 5.35647C15.2214 5.34266 15.2771 5.33375 15.3333 5.33336C15.3896 5.33375 15.4453 5.34266 15.4983 5.35923C15.5475 5.37251 15.599 5.39712 15.6472 5.43108C15.6799 5.45341 15.7101 5.47927 15.7369 5.50832L20.5105 10.0541C20.7187 10.2525 20.7187 10.574 20.5105 10.7724C20.3022 10.9707 19.9645 10.9707 19.7562 10.7724L14.9562 6.20138L15.8667 5.84225V15.4921H14.8L15.7369 5.50832Z"
        fill="white"
      />
      <path
        d="M35.3335 5.33332C35.3335 3.86056 36.0267 2.66666 36.8819 2.66666H57.7851C58.6403 2.66666 59.3335 3.86056 59.3335 5.33332V21.3333C59.3335 22.8061 58.6403 24 57.7851 24H36.8819C36.0267 24 35.3335 22.8061 35.3335 21.3333V5.33332Z"
        fill="#4A4851"
      />
      <path
        d="M38.0002 2.66667C38.0002 1.19391 38.6935 0 39.5486 0H60.4519C61.307 0 62.0002 1.19391 62.0002 2.66667V18.6667C62.0002 20.1394 61.307 21.3333 60.4519 21.3333H39.5486C38.6935 21.3333 38.0002 20.1394 38.0002 18.6667V2.66667Z"
        fill="#35343B"
      />
      <path
        d="M50.5335 15.4911C50.5335 15.6249 50.4832 15.739 50.4038 15.825L50.3773 15.8502C50.357 15.8695 50.3358 15.8869 50.314 15.9023C50.2685 15.9333 50.2182 15.9575 50.1652 15.9741C50.1085 15.9929 50.0541 16.0004 50.0002 16C49.9463 16.0004 49.8919 15.9929 49.8389 15.9769C49.7821 15.9575 49.7318 15.9333 49.6863 15.9023C49.6645 15.8869 49.6433 15.8695 49.623 15.8502L49.5966 15.825C49.5171 15.739 49.4668 15.6249 49.4668 15.4911V5.84122C49.4668 5.16404 50.5335 5.16404 50.5335 5.84122V15.4911L49.623 15.132L50.5335 14.2649V15.4911ZM49.5966 15.825C49.5171 15.739 49.4668 15.6249 49.4668 15.4911V5.84122C49.4668 5.16404 50.5335 5.16404 50.5335 5.84122V15.4911L49.623 15.132L54.423 10.561C54.6313 10.3626 54.969 10.3626 55.1773 10.561C55.3856 10.7593 55.3856 11.0809 55.1773 11.2792L50.4038 15.825C50.3769 15.8541 50.3467 15.8799 50.314 15.9023C50.2659 15.9362 50.2143 15.9608 50.1614 15.9769C50.1121 15.9907 50.0564 15.9996 50.0002 16C49.9439 15.9996 49.8882 15.9907 49.8351 15.9741C49.786 15.9608 49.7345 15.9362 49.6863 15.9023C49.6536 15.8799 49.6234 15.8541 49.5966 15.825L44.823 11.2792C44.6148 11.0809 44.6148 10.7593 44.823 10.561C45.0313 10.3626 45.369 10.3626 45.5773 10.561L50.3773 15.132L49.4668 15.4911V5.84122H50.5335L49.5966 15.825Z"
        fill="white"
      />
    </svg>
  );
}
