import React from "react";
import ACPColumnWidget from "./Column";
import ACPImageWidget from "./Image";
import ACPRowWidget from "./Row";
import ACPTableWidget from "./Table";
import ACPWysiwgWidget from "./Wysiwyg";

export interface ACPWidgetProps {
  widget: any;
}

const ACPWidget: React.FC<ACPWidgetProps> = ({ widget }) => {
  switch (widget.type) {
    case "row":
      return <ACPRowWidget widget={widget} />;
    case "wysiwyg":
      return <ACPWysiwgWidget widget={widget} />;
    case "table":
      return <ACPTableWidget widget={widget} />;
    case "image":
      return <ACPImageWidget widget={widget} />;
    case "column":
      return <ACPColumnWidget widget={widget} />;
    default:
      return <div>unsuporrted type</div>;
  }
};

export default ACPWidget;
