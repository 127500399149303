import React from "react";
import { ProjectType } from "../types";
import WarningIcon from "./Icon/icons/WarningIcon";

interface VersionWarningProps {
  project: ProjectType;
}

const VersionWarning: React.FC<VersionWarningProps> = ({ project }) => {
  if (project.version_status === "CURRENT") return null;
  if (project.version_status === "NEXT")
    return (
      <div className="version-warning">
        <WarningIcon />
        <div className="textarea">
          <h3>Warning</h3>
          <p>
            You are viewing the documentation for an unreleased version of the
            api.
          </p>
        </div>
      </div>
    );
  return (
    <div className="version-warning">
      <WarningIcon />
      <div className="textarea">
        <h3>Warning</h3>
        <p>You are viewing the docs for an older version of the api.</p>
      </div>
    </div>
  );
};

export default VersionWarning;
