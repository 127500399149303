import React, { useEffect, useState } from "react";
import axios from "../plugins/axios";
import AppLoading from "../components/Loading";
import AuthenticationComponent from "../components/AuthenticationComponent";
import ErrorComponent from "../components/ErrorComponent";
import { ProjectType } from "../types";

interface AuthContainerProps {
  project: ProjectType;
}

const withAuth =
  <P extends object>(
    Component: React.ComponentType<P & AuthContainerProps>
  ): React.FC<P & AuthContainerProps> =>
  // eslint-disable-next-line
  (props: AuthContainerProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [failed, setFailed] = useState(false);
    const [requiresAuth, setRequiresAuth] = useState(
      props.project.requires_auth
    );
    const [items, setItems] = useState(props.project.items);

    useEffect(() => {
      loadSSRContent();
    }, []);

    const loadSSRContent = async () => {
      setProjectAndVersionData();
      if (props.project.requires_auth) {
        if (!window.localStorage.getItem("token")) {
          setRequiresAuth(true);
        } else {
          await getAllData();
          setIsLoading(false);
        }
      }
    };

    const setProjectAndVersionData = () => {
      window.localStorage.setItem(
        "application_id",
        String(props.project.project_id)
      );
      window.localStorage.setItem(
        "version_code",
        String(props.project.version_code)
      );
    };

    const getAllData = async () => {
      try {
        const { data } = await axios.get(`docs/get/${props.project.domain}`);
        setItems(data.items);

        setRequiresAuth(false);
        setIsLoading(false);
        return true;
      } catch (error) {
        setFailed(true);
        throw new Error(error);
      }
    };

    const onAuthCompleted = async () => {
      await getAllData();
    };

    if (requiresAuth)
      return (
        <AuthenticationComponent
          project={props.project}
          authComplete={onAuthCompleted}
          darkMode={false}
        />
      );

    if (failed) return <ErrorComponent />;

    return (
      <div>
        {isLoading ? (
          <AppLoading />
        ) : (
          // @ts-ignore
          <Component {...{ ...props, project: { ...props.project, items } }} />
        )}
      </div>
    );
  };

export default withAuth;
