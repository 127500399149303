import React from "react";
import PoweredBy from "./PoweredBy";

export default function ErrorComponent() {
  return (
    <div className="maintenance-screen">
      <div className="box">
        <h4>
          There is an error with the page you are trying to load or it no longer
          exists. Please contact your provider for more details.
        </h4>
        {/* <a href="/" className="home">
          Reload
        </a> */}
      </div>
      <PoweredBy darkMode={true} />
    </div>
  );
}
