import React from "react";

interface ModalProps {
  shown: boolean;
  onClose?: () => void;
  dismissible?: boolean;
  contentSize?: string;
}
const Modal: React.FC<ModalProps> = ({
  shown = false,
  children,
  onClose = () => {},
  dismissible = false,
  contentSize = "w-60 mw7",
}) => {
  const bgClick = (e) => {
    if (dismissible) {
      onClose();
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div onClick={bgClick}>
      {shown && (
        <div className="modal-overlay">
          <div className="flex justify-center items-center h-100">
            <div className={`${contentSize}`} onClick={handleClick}>
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
