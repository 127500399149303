export default function ArrowRight() {
  return (
    <svg
      width="26"
      height="18"
      viewBox="0 0 154 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M140.254 38.9283L105.651 7.16412C105.651 7.16375 105.65 7.16338 105.65 7.16301C105.109 6.68611 104.723 6.05796 104.543 5.35967C104.362 4.66084 104.396 3.9239 104.638 3.24416C104.881 2.56442 105.322 1.97315 105.905 1.54682C106.487 1.12053 107.184 0.878758 107.905 0.852754C108.379 0.834231 108.852 0.909294 109.296 1.07365C109.741 1.23803 110.149 1.4885 110.497 1.81074L151.974 39.8312L140.254 38.9283ZM140.254 38.9283H4.3772H4.05315V38.9282L4.04614 38.9285C2.15329 39.0171 0.520099 40.811 0.608824 42.7039C0.697492 44.5954 2.48913 46.2282 4.38064 46.1414H140.254L105.65 77.8525C105.65 77.8526 105.65 77.8526 105.65 77.8527C105.296 78.1702 105.009 78.5545 104.804 78.9834C104.599 79.4124 104.481 79.8777 104.456 80.3525C104.431 80.8273 104.501 81.3023 104.66 81.7502C104.82 82.1981 105.066 82.6101 105.385 82.9626C105.704 83.315 106.09 83.601 106.52 83.8041C106.949 84.0072 107.415 84.1233 107.89 84.1459C108.365 84.1685 108.84 84.0971 109.287 83.9357C109.734 83.7744 110.145 83.5263 110.496 83.2057L110.496 83.2055L151.974 45.1846L140.254 38.9283ZM152.852 43.9724C152.648 44.4331 152.349 44.8458 151.975 45.1838L152.852 43.9724ZM152.852 43.9724C153.057 43.5113 153.163 43.0124 153.163 42.5079M152.852 43.9724L153.163 42.5079M153.163 42.5079C153.163 42.0033 153.057 41.5044 152.852 41.0434M153.163 42.5079L152.852 41.0434M152.852 41.0434C152.648 40.5826 152.349 40.1699 151.975 39.8319L152.852 41.0434Z"
        className="ico"
        strokeWidth="0.3"
      />
    </svg>
  );
}
