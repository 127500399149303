import React, { useEffect, useState } from "react";
import Head from "next/head";
import axios from "../plugins/axios";
import PoweredBy from "./PoweredBy";
import ArrowRight from "./Icon/icons/ArrowRight";
import { Circles } from "react-loader-spinner";
import SiteLogo from "./AppLogo";
import { ProjectType } from "../types";

interface AuthenticationComponentProps {
  project: ProjectType;
  authComplete: (val: boolean) => void;
  darkMode: boolean;
}

const AuthenticationComponent: React.FC<AuthenticationComponentProps> = ({
  project,
  authComplete,
  darkMode,
}) => {
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setError(null);
  }, [password]);

  const submit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const { data } = await axios.post("docs/auth", {
        passcode: password,
        appId: project.id,
      });

      window.localStorage.setItem("token", data.token);
      window.localStorage.setItem("application_id", String(project.project_id));
      window.localStorage.setItem("version_code", String(project.version_code));

      authComplete(data.token);
    } catch (error) {
      setError(error.response.data.message);
    }
    setSubmitting(false);
  };

  return (
    <div>
      <Head>
        <title>{project.name}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="authenticationForm flex items-center w-100 h-100">
        <form
          onSubmit={submit}
          className="br4 pa4 w-60 h-50 mh6 mw7 flex flex-column items-center justify-between"
        >
          <div></div>
          <div className="flex flex-column items-center justify-center w-70">
            <div className="h3">
              <SiteLogo
                {...{
                  light: project.logo_light,
                  dark: project.logo,
                  text: project.project_name,
                  darkMode,
                }}
              />
            </div>
            <h3 className="pt1">{project.page_sub}</h3>

            <div
              className={`password mt4 br-pill bg-db-gray2 db-gray w-100 ${
                error && "error"
              }`}
            >
              <input
                name="password"
                id="password"
                type="password"
                value={password}
                autoComplete="password"
                required
                onChange={(e) => setPassword(e.target.value)}
                className="bg-db-gray2 white db"
              />
              <label className="" htmlFor="password">
                Enter Password
              </label>

              <button className={`f5 ${password.length > 5 && "valid"} `}>
                {submitting ? (
                  <Circles color="white" height={20} width={20} />
                ) : (
                  <ArrowRight />
                )}
              </button>
            </div>
            <div className={`error-message pl4 w-100 f6 ${error && "shown"}`}>
              {error}
            </div>
          </div>
          <div>
            {project.support_url && (
              <a
                href={project.support_url}
                className="mdb-gray f5"
                target="_blank"
                rel="noopener noreferrer"
              >
                Password Support
              </a>
            )}
          </div>
        </form>
      </div>
      <PoweredBy darkMode={true} />
    </div>
  );
};

export default AuthenticationComponent;
