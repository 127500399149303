import { useCallback, useEffect, useRef, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import useKeyPress from "../utils/useKeyPress";
import Close from "./Icon/icons/Close";
import Esc from "./Icon/icons/Esc";
import Tab from "./Icon/icons/Tab";
import UpDown from "./Icon/icons/UpDown";
import SingleMenuItem from "./SingleMenuItem";
import { useRouter } from "next/router";
import SiteLogo from "./AppLogo";
import VersionSelectField from "./VersionSelectField";
import { ProjectType } from "../types";
import { isGroup } from "../utils/contentTypeCheck";
import VersionWarning from "./VersionWarning";

interface DocsSidebarProps {
  project: ProjectType;
  darkMode: boolean;
  toggleDarkMode: () => void;
  activeRef: string;
  setActiveRef: (v: string) => void;
}

const DocsSidebar: React.FC<DocsSidebarProps> = ({
  project,
  darkMode,
  toggleDarkMode,
  activeRef,
  setActiveRef,
}) => {
  const router = useRouter();

  const downPress = useKeyPress("ArrowDown");
  const tabPress = useKeyPress("Tab");
  const upPress = useKeyPress("ArrowUp");
  const escapePress = useKeyPress("Escape");
  const onEnter = useKeyPress("Enter");
  const searchBoxDiv = useRef("");

  const [search, setSearch] = useState("");

  const [spList, setSpList] = useState([]);
  const [elPosList, setElPosList] = useState([]);

  const [itemsCount, setItemsCount] = useState(0);
  const [displayItems, setDisplayItems] = useState(project.items);

  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsHover, setSearchResultsHover] = useState(null);

  const searchResultClick = useCallback(
    (item, behave = "smooth") => {
      try {
        setActiveRef(item.unique_id);
        document
          .getElementById(item.unique_id)
          // @ts-ignore
          .scrollIntoView({ behavior: behave });
        setSearch("");
      } catch (error) {}
    },
    [setActiveRef]
  );

  // key event handlers
  useEffect(() => {
    if (
      (downPress || tabPress) &&
      searchResults.length - 1 !== searchResultsHover
    ) {
      setSearchResultsHover(searchResultsHover + 1);

      if (searchResultsHover > 1) {
        // @ts-ignore
        searchBoxDiv.current.scrollTop += 60;
      }
    }
    if (upPress && searchResultsHover !== 0) {
      setSearchResultsHover(searchResultsHover - 1);
      if (searchResultsHover > 0) {
        // @ts-ignore
        searchBoxDiv.current.scrollTop -= 60;
      }
    }
    if (escapePress) {
      doSearch("");
    }

    if (onEnter) {
      searchResultClick(searchResults[searchResultsHover]);
    }
  }, [
    downPress,
    upPress,
    escapePress,
    tabPress,
    onEnter,
    searchResults,
    searchResultsHover,
    searchResultClick,
  ]);

  const doSearch = (term) => {
    // @ts-ignore
    searchBoxDiv.current.scrollTop = 0;
    setSearch(term);
  };

  const itemFilter = useCallback(
    (item) => {
      return (
        JSON.stringify(item).toLowerCase().includes(search.toLowerCase()) &&
        item.content_model != "ProjectGroup"
      );
    },
    [search]
  );

  useEffect(() => {
    let list = [];

    displayItems.forEach((a) => {
      if (isGroup(a)) {
        list = [...list, a, ...a.items.map((e) => e)];
      } else {
        list.push(a);
      }
    });

    list = list.filter(itemFilter);
    setSearchResults(list);

    if (list.length > 0) {
      setSearchResultsHover(-1);
    } else {
      setSearchResultsHover(null);
    }
  }, [displayItems, itemFilter, search]);

  useEffect(() => {
    document.querySelector(".MainContent").addEventListener("scroll", () => {
      var scrollPosition =
        document.querySelector(".MainContent").scrollTop +
        window.innerHeight * 0.75;

      elPosList.forEach((el) => {
        if (el.pos <= scrollPosition) {
          setActiveRef(el.key);
        }
      });
    });
  }, [elPosList, itemsCount, setActiveRef]);

  useEffect(() => {
    // Select the node that will be observed for mutations
    const targetNode = document.querySelector(".MainContent");

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = function (mutationsList, observer) {
      setItemsCount(document.querySelectorAll(".section").length);
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);

    // window.addEventListener("keydown", handleKeyDown, { once: false });

    // return () => {
    //   window.removeEventListener("keydown", handleKeyDown);
    // };
  }, []);

  useEffect(() => {
    let setters = project.items;

    setDisplayItems(setters);

    const fullList = getFullItemsList(setters);

    setElPosList(
      fullList.map((li) => ({
        key: li,
        pos: document.getElementById(li).offsetTop,
      }))
    );

    setSpList(fullList);

    if (router.asPath != "/" && process.browser) {
      searchResultClick({ unique_id: router.asPath.replace("/#", "") }, "auto");
    }
  }, [project.items, router.asPath, searchResultClick]);

  const getFullItemsList = (dpItems) => {
    let list = [];

    dpItems.forEach((a) => {
      if (a.items && a.items.length > 0) {
        list = [...list, a.unique_id, ...a.items.map((e) => e.unique_id)];
      } else {
        list.push(a.unique_id);
      }
    });
    return list;
  };

  return (
    <div className="DocsSidebar">
      <div className="TopBar relative">
        <div></div>
        <div className="bg-db-3rd br5 flex-column items-start w-50 mw6 absolute top-1 flex overflow-hidden">
          <div className="flex items-center justify-between w-100 pa1 pr4 pl4">
            <div className="flex items-center w-100">
              <IoIosSearch size={24} />
              <input
                placeholder="Search documentation..."
                value={search}
                className="bg-transparent f6 lh-copy ml1 w-100 white"
                onChange={(e) => doSearch(e.target.value)}
              />
            </div>
            {search.length > 0 ? (
              <a
                className="db flex items-center"
                onClick={() => doSearch("")}
                style={{ width: "18px", height: "18px" }}
              >
                <Close />
              </a>
            ) : (
              <div className="pa1 db h2 flex items-center"></div>
            )}
          </div>
          <div className={"w-100 pr2"}>
            <div
              className={`results w-100 ${search.length > 2 && "shown"}`}
              // @ts-ignore
              ref={searchBoxDiv}
            >
              {searchResults.map((ii, ik) => (
                <a
                  className={`result bb pl4 pa3 w-93 b--white-10 pointer ${
                    searchResultsHover == ik && "active"
                  }`}
                  href={`/#${ii.unique_id}`}
                  key={ik}
                  tabIndex={0}
                  onClick={(e) => {
                    e.preventDefault();
                    searchResultClick(ii);
                  }}
                >
                  <p className="f4-5 lh-solid">{ii.name}</p>
                  {ii.content_model == "ProjectEndpoint" && (
                    <span className="f6 lh-solid mt1">{ii.endpoint}</span>
                  )}
                </a>
              ))}
              {searchResults.length == 0 && (
                <div className="f6 pa4 w-100 flex items-center justify-center">
                  No results for: &ldquo;{search}&ldquo;
                </div>
              )}
            </div>
          </div>

          {search.length > 2 && searchResults.length > 0 && (
            <div className="bg-black pa2 ph4 w-100 flex items-center justify-between">
              <div className="pv1 gray text-xs flex items-center">
                <Tab /> &nbsp; or &nbsp;
                <UpDown /> &nbsp; to navigate
              </div>
              <div className="pv1 gray text-xs flex items-center">
                <Esc /> &nbsp; to close
              </div>
            </div>
          )}
        </div>

        <div className="flex items-center mr1">
          {project.links.map((link, ind) => (
            <a
              key={ind}
              href={link.url}
              className="mr4 link dim b"
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.title}
            </a>
          ))}
          {project.support_url && (
            <a
              href={project.support_url}
              className="mr4 link dim b"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support
            </a>
          )}
          <input
            type="checkbox"
            onChange={toggleDarkMode}
            checked={darkMode ? true : false}
          />
        </div>
      </div>
      <div className="LogoAndColorMode">
        <div className="logoAndThemeToggle">
          <SiteLogo
            darkMode={darkMode}
            dark={project.logo_light}
            light={project.logo}
            text={project.project_name}
          />
        </div>
        <h3 className="pt1">{project.page_sub}</h3>
      </div>
      <div className="navigationItems hide-scrollbar">
        {displayItems.map((item, index) => (
          <SingleMenuItem
            item={item}
            key={index}
            index={index}
            activeRef={activeRef}
            setActiveRef={setActiveRef}
            expanded={
              activeRef === item.unique_id ||
              (isGroup(item) &&
                item.items.map((e) => e.unique_id).includes(activeRef))
            }
          />
        ))}
      </div>
      <div className="versionAndEcoSystem">
        <VersionWarning project={project} />
        {project.versions.length > 1 && (
          <VersionSelectField
            value={project.version_code}
            options={project.versions.map((s) => ({
              key: s.name,
              val: s.version_code,
            }))}
            onChange={async (val) => {
              const projectVersion = project.versions.find(
                (ver) => ver.version_code === val
              );

              if (projectVersion && val !== project.version_code) {
                window.localStorage.setItem("version_code", String(val));
                if (projectVersion.state === "CURRENT") {
                  window.location.replace(`/`);
                } else {
                  window.location.replace(`/${val}`);
                }
              }
            }}
          />
        )}
        <div className="copyright">{project.copyright}</div>
      </div>
    </div>
  );
};

export default DocsSidebar;
