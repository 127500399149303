import { CopyToClipboard as CTC } from "react-copy-to-clipboard";
import useToast from "../utils/withToast";

interface CopyToClipboardProps {
  value: string;
  className?: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  children,
  value,
  className = "",
}) => {
  const toast = useToast();

  return (
    <div data-tip="Copy to clipboard" data-place="bottom" className={className}>
      <CTC
        text={value}
        onCopy={() => {
          toast("Copied to clipboard!");
        }}
      >
        {children}
      </CTC>
    </div>
  );
};

export default CopyToClipboard;
