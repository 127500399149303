import React from "react";
import { ACPWidgetProps } from ".";

const ACPTableWidget: React.FC<ACPWidgetProps> = ({ widget }) => {
  return (
    <div className="mv3">
      <table className="f6 w-100 center" cellSpacing="0">
        <thead>
          <tr>
            {widget.headers.map((head, headInd) => (
              <th key={headInd} className="fw6 bb b--gray-80 tl pb3 pr3">
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="lh-copy">
          {widget.rows.map((rw, rwI) => (
            <tr key={rwI}>
              {rw.map((cell, cellI) => (
                <td key={cellI} className="pv3 pr3 bb b--gray">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ACPTableWidget;
