interface SiteLogoProps {
  light: string;
  dark: string;
  text: string;
  darkMode: boolean;
}

const SiteLogo = ({ light, dark, text, darkMode }: SiteLogoProps) => {
  const imageValid = (img) => {
    return img && img !== "";
  };

  if (darkMode && imageValid(dark))
    return <img src={dark} className="logo h2" alt={text} />; // eslint-disable-line

  if (!darkMode && imageValid(light))
    return <img src={light} className="logo h2" alt={text} />; // eslint-disable-line

  return <div className={`f4 mb2 b`}>{text}</div>;
};

export default SiteLogo;
