import React from "react";
import { ProjectItemContentPageType } from "../../types";
import ACPWidget from "../ACPWidgets";

interface AdvancedContentPageProps {
  item: ProjectItemContentPageType;
}

const AdvancedContentPage: React.FC<AdvancedContentPageProps> = ({ item }) => {
  return (
    <div className="ph4">
      {item.acp_content.map((widget) => (
        <ACPWidget key={widget.id} widget={widget} />
      ))}
    </div>
  );
};

export default AdvancedContentPage;
