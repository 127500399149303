import { useEffect, useState } from "react";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";
import JSONPretty from "react-json-pretty";
import Modal from "./Modal";
let JSONPrettyMon = require("react-json-pretty/dist/monikai");

interface JsonFieldProps {
  title: string;
  value: Object;
}

const JsonField: React.FC<JsonFieldProps> = ({ title, value }) => {
  const [style, setStyles] = useState(JSONPrettyMon);
  const [styleFull, setStylesFull] = useState(JSONPrettyMon);

  const [showFull, setShowFull] = useState(false);

  useEffect(() => {
    setStyles({
      boolean: "font-size:0.8rem;color:#da002a;",
      error: "font-size:0.8rem;color:white;background:transparent;",
      key: "font-size:0.8rem;color:white;line-height:1rem;",
      main: "font-size:0.8rem;color:white;background:transparent;line-height:1rem;overflow:hidden",
      string:
        "font-size:0.8rem;color:#E3E55F;word-wrap:break-word;white-space:nowrap;text-overflow:ellipsis;max-width:80%;display:inline-block;line-height:1rem;max-width:50%",
      value: "font-size:0.8rem;color:#BC3131;line-height:1rem;",
    });
    setStylesFull({
        boolean: "font-size:0.9rem;color:#da002a;",
        error: "font-size:0.9rem;color:white;background:transparent;",
        key: "font-size:0.9rem;color:white;line-height:1.2rem;",
        main: "font-size:0.9rem;color:white;background:transparent;line-height:1.5rem;overflow:hidden",
        string:
          "font-size:0.9rem;color:#E3E55F;word-wrap:break-word;white-space:nowrap;text-overflow:ellipsis;max-width:80%;display:inline-block;line-height:1.5rem;max-width:50%",
        value: "font-size:0.9rem;color:#BC3131;line-height:1.5rem;",
      });
  }, []);

  return (
    <div className="right-item">
      <div className="seperator bg-db-gray">
        <h3>{title}</h3>

        <div onClick={() => setShowFull(true)} role="button">
          <BiFullscreen />
        </div>
      </div>
      <JSONPretty
        className="endpoint-request"
        data={value}
        theme={style}
      ></JSONPretty>
      <Modal
        shown={showFull}
        dismissible={true}
        onClose={() => setShowFull(false)}
        contentSize="w-100 h-100 bg-black"
      >
        <div className="seperator bg-db-gray">
          <h3>{title}</h3>

          <div onClick={() => setShowFull(false)} role="button">
            <BiExitFullscreen />
          </div>
        </div>
        <JSONPretty
          className="endpoint-request full"
          data={value}
          theme={styleFull}
        ></JSONPretty>
      </Modal>
    </div>
  );
};

export default JsonField;
