import React from "react";
import ReactDOM from "react-dom";
import { BsCheckAll, BsInfoCircle } from "react-icons/bs";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const useToast = () => {
  const hideToast = () => {
    ReactDOM.render(null, document.getElementById("toast-message"));
    document.getElementById("toast-message").setAttribute("class", "");

  };

  const GetIcon = ({ type }) => {
    switch (type) {
      case "info":
        return <BsInfoCircle />;
      case "error":
        return <BsInfoCircle />;
      default:
        return <BsCheckAll />;
    }
  };

  const showTheToastMessage = async (
    message = "Success!",
    type = "success"
  ) => {
    const toastContent = (
      <div className={`inner-bit ${type}`}>
        <p>{message}</p>
        <GetIcon type={type} />
      </div>
    );

    ReactDOM.render(toastContent, document.getElementById("toast-message"));
    document.getElementById("toast-message").setAttribute("class", "shown");

    await sleep(2000);

    hideToast();
  };

  return showTheToastMessage;
};

export default useToast;
